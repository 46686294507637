import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import ProjectCard from './ProjectCard'
import { device } from '../styles/config'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 13.88rem;

  @media ${ device.sm } {
    margin-bottom: 6.9rem;
  }
`

const ProjectCardBlock = ({ projects }) => {
  const sideBySide = projects.length > 1

  return (
    <Container>
      <ProjectCard
        image={projects[0].homepageImage}
        name={projects[0].name}
        nameAnimated={projects[0].nameAnimated}
        breakAnimated={projects[0].breakAnimated}
        projectType={projects[0].projectType}
        location={projects[0].location}
        year={projects[0].year}
        miscelaneous={projects[0].miscelaneous}
      />
      {sideBySide &&
        <ProjectCard
          image={projects[1].homepageImage}
          name={projects[1].name}
          nameAnimated={projects[1].nameAnimated}
          breakAnimated={projects[1].breakAnimated}
          projectType={projects[1].projectType}
          location={projects[1].location}
          year={projects[1].year}
          miscelaneous={projects[1].miscelaneous}
        />
      }
    </Container>
  )
}

// ProjectCardBlock.propTypes = {
//   siteTitle: PropTypes.string,
//   currentPathname: PropTypes.string,
// }

// ProjectCardBlock.defaultProps = {
//   siteTitle: ``,
// }

export default ProjectCardBlock
