import PropTypes from 'prop-types'
import React, { createRef, Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import GatsbyImage from 'gatsby-image'

import slugify from '../utils/slugify'
import ShuffleText from '../utils/shuffleText'
import isMobile from '../utils/isMobile'

const Container = styled(Link)`
  width: 42%;

  &:only-child {
    width: 100%;
  }

  div, span {
    font-family: 'GT America';
    font-size: 0.94rem;
    color: var(--color-text);
  }
`

const NameAnimated = styled.span``

const Image = styled(GatsbyImage)`
  width: ${ ({ fluid }) => fluid.aspectRatio < 1 ? '70%' : '100%' };
  margin-bottom: 1.22rem;
`

class ProjectCard extends Component {
  constructor (props) {
    super(props)

    this.animationTarget = createRef(null)
    this.shouldAnimate = this.props.nameAnimated && !isMobile()
    this.shuffle = null
    this.timeout = null
  }

  componentDidMount () {
    if (this.shouldAnimate) {
      this.shuffle = new ShuffleText(this.animationTarget.current, {})
      setTimeout(() => {
        this.animationTarget && this.animationTarget.current && this.animationTarget.current.parentElement && fixSizing(this.animationTarget.current.parentElement)
      }, 300)
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  startShuffle = () => {
    if (this.shouldAnimate && this.shuffle) {
      clearTimeout(this.timeout)
      fixBreak(this.animationTarget.current)
      this.timeout = setTimeout(() => {
        restoreBreak(this.animationTarget.current)
      }, 300)
      this.shuffle.start()
    }
  }

  endShuffle = () => {
    if (this.shouldAnimate && this.shuffle) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        restoreBreak(this.animationTarget.current)
      }, 300)
    }
  }

  render () {
    const {
      image,
      name,
      nameAnimated,
      breakAnimated,
      projectType,
      location,
      year,
      miscelaneous
    } = this.props

    return (
      <Container
        to={`/${ slugify(name) }`}
        onMouseEnter={this.startShuffle}
        onMouseLeave={this.endShuffle}
      >
        <Image fluid={image.fluid}/>
        <div>
          <span>{name}</span>
          {nameAnimated && (
            <>
              <span> - </span>
              {breakAnimated && <br/>}
              <NameAnimated ref={this.animationTarget}>{nameAnimated}</NameAnimated>
            </>
          )}
        </div>
        <div>{projectType}</div>
        <span>{location}</span> <span>{year}</span>
        <div>{miscelaneous}</div>
      </Container>
    )
  }
}

function fixSizing (element) {
  element.style.width = element.getBoundingClientRect().width + 'px'
  element.style.height = element.getBoundingClientRect().height + 'px'
  element.style.overflow = 'hidden'
}

function fixBreak (element) {
  element.style.wordBreak = 'break-all'
}

function restoreBreak (element) {
  element.style.wordBreak = 'initial'
}

ProjectCard.propTypes = {
  image: PropTypes.object,
  name: PropTypes.string,
  projectType: PropTypes.string,
  location: PropTypes.string,
  year: PropTypes.number,
  miscelaneous: PropTypes.string,
}

export default ProjectCard
