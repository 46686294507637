import PropTypes from 'prop-types'
import React, { useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Masonry from 'react-masonry-css'
import * as basicScroll from 'basicscroll'
import { Col } from 'react-styled-flexboxgrid'

import Navigation from './navigation'
import ProjectCardBlock from './ProjectCardBlock'

import { useProjectList } from '../hooks/useProjectList'
import useWindowHeight from '../hooks/useWindowHeight'
import config, { device } from '../styles/config'

const Container = styled.div`
  width: 100%;
  max-width: ${ config.pageWidth };
  margin: 0 auto;
  padding-left: ${ config.pagePadding };
  padding-right: ${ config.pagePadding };
  z-index: 1;
  position: relative;

  @media ${ device.sm } {
    padding-left: ${ config.pagePaddingMobile };
    padding-right: ${ config.pagePaddingMobile };
  }
`

const ScrollTarget = styled.div`
  margin-top: ${ props => props.windowHeight ? `${ props.windowHeight - 50 }px` : '95vh' };
  width: 100%;
  height: ${ props => props.windowHeight ? `${ props.windowHeight }px` : '100vh' };

  &:before {
    content: '';
    display: block;
    height: ${ props => props.windowHeight ? `${ 2 * props.windowHeight }px` : '200vh' };
    bottom: ${ props => props.windowHeight ? `${ props.windowHeight }px` : '100vh' };
    position: relative;
    background-color: ${ props => props.color };
    opacity: var(--basicOpacity);
    transition: opacity 0.1s;
    will-change: opacity;
    margin-left: -600px;
    margin-right: -600px;
  }
`

const ProjectListNavigation = styled(Navigation)`
  opacity: var(--navigationOpacity);
  transition: opacity 0.1s;
  will-change: opacity;
  margin-bottom: 5.55rem;
  padding-left: 0;
  padding-right: 0;
  width: initial;
  margin-left: -2.775rem;
  margin-right: -2.775rem;

  @media ${ device.sm } {
    margin-left: 0;
    margin-right: 0;
    
    ${ Col } {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`

const ProjectList = ({ bottom, color }) => {
  const projectBlocks = useProjectList()
  const windowHeight = useWindowHeight()

  let ref

  if (bottom) {
    ref = useRef()

    useEffect(() => {
      const instance = basicScroll.create({
        elem: ref.current,
        from: 'top-bottom',
        to: 'bottom-bottom',
        props: {
          '--basicOpacity': {
            from: 0.01,
            to: 0.99
          },
          '--descriptionOpacity': {
            from: 0.99,
            to: 0
          }
        }
      })

      instance.start()

      const navigationInstance = basicScroll.create({
        elem: document.querySelectorAll('nav')[1],
        from: 'top-middle',
        to: 'top-top',
        props: {
          '--navigationOpacity': {
            from: 0.01,
            to: 0.99
          }
        }
      })
      navigationInstance.start()
    }, [])
  }

  return (
    <Container>
      {bottom && <ScrollTarget ref={ref} windowHeight={windowHeight} color={color}/>}
      {bottom && <ProjectListNavigation />}
      <Masonry
        breakpointCols={{ defualt: 2, 700: 1 }}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {projectBlocks.map((project, index) => (
          <ProjectCardBlock key={index} {...project}/>
        ))}
      </Masonry>
    </Container>
  )
}

// ProjectList.propTypes = {
//   projects: PropTypes.array,
// }

export default ProjectList
