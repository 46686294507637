import { useStaticQuery, graphql } from 'gatsby'

export const useProjectList = () => {
  const { datoCmsHomepage: { projectBlocks } } = useStaticQuery(
    graphql`
      query ProjectListQuery {
        datoCmsHomepage {
          projectBlocks {
            projects {
              name
              nameAnimated
              breakAnimated
              projectType
              location
              year
              miscellaneous
              homepageImage {
                fluid(maxWidth: 800, imgixParams: {
                  fm: "jpg",
                  auto: "compress"
                }) {
                  ...GatsbyDatoCmsFluid_noBase64
                }
              }
            }
          }
        }
      }
    `
  )

  return projectBlocks
}
