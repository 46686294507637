import { useState, useEffect } from 'react'
import isMobile from '../utils/isMobile'

function getHeight () {
  return window.innerHeight
}

function useWindowHeight () {
  if (typeof window === 'undefined') {
    return null
  }

  let [windowHeight, setWindowHeight] = useState(getHeight())
  let event = isMobile() ? 'orientationchange' : 'resize'

  function handleResize () {
    setWindowHeight(getHeight())
  }

  useEffect(() => {
    window.addEventListener(event, handleResize)

    return () => {
      window.removeEventListener(event, handleResize)
    }
  }, [])

  return windowHeight
}

export default useWindowHeight
